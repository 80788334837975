var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"login-page"},[_c('v-container',{staticClass:"fill-height justify-center",attrs:{"id":"login","tag":"section"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-slide-y-transition',{attrs:{"appear":""}},[_c('v-card',{staticClass:"px-5 py-3 overflow-visible",attrs:{"light":"","max-width":"100%","width":"400"}},[_c('div',{staticClass:"d-flex justify-center mb-3 mt-3"},[(_vm.projectType === _vm.constant.INTERNATIONAL)?[_c('v-img',{attrs:{"max-width":"150","src":require("@/assets/logo-rino.png")}})]:[_c('v-img',{attrs:{"max-width":"150","src":require("@/assets/logo.png")}})]],2),(_vm.errorMessage)?_c('MaterialAlert',{staticClass:"mb-0 mt-2",attrs:{"color":"warning","dark":""}},[_c('span',{staticClass:"text-uppercase",domProps:{"textContent":_vm._s(_vm.errorMessage)}})]):_vm._e(),_c('validation-observer',{ref:"validator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-card-text',{staticClass:"text-center"},[_c('validation-provider',{attrs:{"rules":"required","name":"Tên đăng nhập"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-2",attrs:{"color":"secondary","label":"Tên đăng nhập...","prepend-icon":"mdi-face","placeholder":"Tên đăng nhập","error-messages":errors,"outlined":"","autofocus":true},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return handleSubmit(_vm.login)}},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"Mật khẩu"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-2",attrs:{"color":"secondary","label":"Mật khẩu...","placeholder":"Mật khẩu","prepend-icon":"mdi-lock-outline","error-messages":errors,"outlined":"","append-icon":_vm.visibleConfirmPassword
                                                ? 'mdi-eye'
                                                : 'mdi-eye-off',"type":_vm.visibleConfirmPassword
                                                ? 'password'
                                                : 'text'},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return handleSubmit(_vm.login)},"click:append":function () { return (_vm.visibleConfirmPassword =
                                                    !_vm.visibleConfirmPassword); }},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('v-btn',{staticClass:"v-btn--text success--text",attrs:{"color":""},on:{"click":function($event){return handleSubmit(_vm.login)}}},[_vm._v(" Đăng nhập ")])],1)]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }